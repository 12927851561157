import React, {useEffect} from "react";
import prevIcon from "../assets/img/icons/prevArrow.svg";
import nextIcon from "../assets/img/icons/nextArrow.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";
import Multiple_Product_Card from "../Multiple_Product_Card/Multiple_Product_Card";
import 'swiper/css';
import 'swiper/css/navigation';
import ProductCard from "../ProductCard/ProductCard";
import {setDrawerOpen} from "../store/actions/drawerAction";
import {setSelectedProducts} from "../store/actions/selectedProductActions";
import {setItemType} from "../store/actions/selectedItemTypeAction";
import {useDispatch} from "react-redux";
import {extractData} from "../../utils";

export default function Custom_Swiper({ array }) {
    const dispatch = useDispatch();

    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : { products: [] };
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return { isActive: false, count: 0 };
        }
    };

    return (
        <div className="w-full h-72 flex items-center gap-3 relative">
            {array.map((product, index) => {
                const prevButtonClass = `image-swiper-button-prev__${index}`;
                const nextButtonClass = `image-swiper-button-next__${index}`;

                return (
                    <div key={index} className="w-full h-72 flex items-center gap-3 relative">
                        <button className={`swiper-button ${prevButtonClass} absolute z-10 top-[20%] left-0`}>
                            <img src={prevIcon} className="w-fit h-20 object-contain" />
                        </button>
                        <button className={`swiper-button ${nextButtonClass} absolute z-10 top-[20%] right-0 text-3xl text-white`}>
                            <img src={nextIcon} className="w-fit h-20 object-contain" />
                        </button>
                        <Swiper
                            navigation={{
                                nextEl: `.${nextButtonClass}`,
                                prevEl: `.${prevButtonClass}`,
                                disabledClass: "swiper-button-disabled"
                            }}
                            modules={[Navigation, Autoplay]}
                            loop={true}
                            className="w-full h-full"
                            spaceBetween={10}
                            centeredSlides={true}
                            slidesPerView={1}
                            breakpoints={{
                                480: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                                },
                                600: {
                                    slidesPerView: 3,
                                    spaceBetween: 20
                                },
                                800: {
                                    slidesPerView: 4,
                                    spaceBetween: 30
                                },
                                1000: {
                                    slidesPerView: 4,
                                    spaceBetween: 30
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                                },
                                1280: {
                                    slidesPerView: 3,
                                    spaceBetween: 10
                                },
                                1440: {
                                    slidesPerView: 4,
                                    spaceBetween: 10
                                },
                                1918: {
                                    slidesPerView: 5,
                                    spaceBetween: 10
                                }
                            }}
                        >
                            <SwiperSlide className="w-auto h-full">
                                <div className="w-full flex justify-center">
                                    <ProductCard
                                        onClick={() => {
                                            dispatch(setDrawerOpen(true));
                                            dispatch(setSelectedProducts(product));
                                            dispatch(setItemType("saleProduct"));
                                        }}
                                        key={product.id}
                                        cover={product.cover}
                                        percentPromo={extractData(product.description).discount}
                                        capacity={product.brief_description}
                                        title={product.name}
                                        oldPrice={extractData(product.description)?.minus || 0}
                                        price={product.price}
                                        id={product.id}
                                        badgeText={product.brands}
                                        initialState={getProductCount(product.id)}
                                    />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                );
            })}
        </div>
    );
}

