import React, {useEffect, useState} from "react";
import style from "./style.module.css";
import prevIcon from "../../../../components/assets/img/icons/prevArrow.svg";
import nextIcon from "../../../../components/assets/img/icons/nextArrow.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {RECOMENDATION} from "../../../../CustomData/CustomData";
import {FaMinus} from "react-icons/fa";
import {CgMathPlus} from "react-icons/cg";
import {useDispatch, useSelector} from "react-redux";

import 'swiper/css';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {setSelectedProducts} from "../../../../components/store/actions/selectedProductActions";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import {message} from "antd";
import {extractData} from "../../../../utils";

export default function Mob_SelectedProductContent() {


    const [isActive,setActive] = useState(false)
    const [quantity,setQuantity] = useState(0)
    const [about,setAbout] = useState();
    const [myCount, setMyCount] = useState(0);
    const dispatch = useDispatch();
    const selectedProduct = useSelector((state)=>state.selectedProduct.selectedProducts)

    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : {products: []};
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0 ? true : false,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return {isActive: false, count: 0};
        }
    };

    const updateBasket = (productId, type) => {
        const storedData = localStorage.getItem("basket");
        const parsedData = storedData ? JSON.parse(storedData) : { products: [] };

        // Проверяем, есть ли элемент с данным product_id
        const existingProductIndex = parsedData.products.findIndex(product => product.product_id === productId);

        if (existingProductIndex !== -1) {
            if (type === 'increment') {
                parsedData.products[existingProductIndex].count++;
            } else if (type === 'decrement') {
                if ( parsedData.products[existingProductIndex].count >0){
                    parsedData.products[existingProductIndex].count--;
                } else {
                    message.info('продукт не может быть ниже одного количество')
                }

            }
        } else {
            // Элемент не найден - добавляем новый элемент
            parsedData.products.push({ product_id: productId, count: 1 });
        }

        // Сохраняем обновленные данные в localStorage
        localStorage.setItem("basket", JSON.stringify(parsedData));
        setMyCount(getMycount(selectedProduct.id, parsedData));
    };


    const getMycount = (productId, data) => {
        const existingProductIndex = data?.products?.findIndex(product => product?.product_id === productId);
        if (existingProductIndex !== -1) {
            return data?.products[existingProductIndex]?.count;
        } else {
            return 0;
        }
    };

    const handleIncrement = () => {
        updateBasket(selectedProduct.id, 'increment');
    };

    const handleDecrement = () => {
        updateBasket(selectedProduct.id, 'decrement');
    };

    useEffect(()=>{
        const storedData = localStorage.getItem("basket");
        const parsedData = storedData ? JSON.parse(storedData) : { products: [] };
        const myCount = getMycount(selectedProduct.id, parsedData);
        setMyCount(myCount)
        setAbout(extractData(selectedProduct.description))
    },[quantity,selectedProduct])

    return<>
        <div className={'w-full bg-[#ffffff] h-auto flex flex-col items-start  '}>

            {/*This left side*/}
            <div className={style.productWrapper}>
                {/*Swiper place*/}
                <div className={'w-full h-96 flex items-center gap-3 relative rounded-xl overflow-hidden'}>

                    <button className={`swiper-button image-swiper-button-prev__ absolute z-10 `}>

                        {/*<IoIosArrowBack/>*/}
                        <img src={prevIcon} className={'w-fit h-24 object-contain'}/>

                    </button>
                    <button className={`swiper-button image-swiper-button-next__ absolute z-10 right-0`}>

                        {/*<IoIosArrowForward/>*/}
                        <img src={nextIcon} className={'w-fit h-24 object-contain'}/>

                    </button>

                    <div className={'px-3 py-1 rounded-full text-white bg-[#404040] absolute top-3 left-3 z-10 text-sm font-semibold '}>-{selectedProduct.percentPromo}%</div>
                    <Swiper
                        style={{
                            "--swiper-pagination-color": "#404040",
                            "--swiper-pagination-bullet-size": "10px",
                        }}
                        navigation={{
                            nextEl: ".image-swiper-button-next__",
                            prevEl: ".image-swiper-button-prev__",
                            disabledClass: "swiper-button-disabled",
                        }}
                        pagination={{clickable: true}}
                        modules={[Navigation , Pagination , Autoplay]}
                        speed={1000}
                        loop={true}
                        className={'w-full h-full bg-[#f2f2f2]'}
                        spaceBetween={0}
                        slidesPerView={1}
                    >

                        {selectedProduct?.cover?.map((value, index)=> <SwiperSlide className={'w-full h-full '}>
                            <img key={index} src={`https://api.osonexpress.uz/${value}`} className={'w-full h-full object-contain  '}/>
                        </SwiperSlide>)}


                    </Swiper>

                </div>

                <div className={'w-full h-auto flex flex-col items-start p-2 '}>

                    <div className={'text-2xl font-bold '}>{selectedProduct.name}</div>
                    {/*<div className={'text-xl font-bold opacity-50 '}>{selectedProduct.capacity}</div>*/}

                    <ul className={'list-disc mt-2 '}>
                        {selectedProduct && selectedProduct.information && selectedProduct.information[0] && selectedProduct.information[0].benefits &&
                            selectedProduct.information[0].benefits.map((value, index) => (
                                <li key={index} className={'list-disc text-sm font-semibold mt-2'}> • {value}</li>
                            ))
                        }
                    </ul>

                    <div className={'w-full h-0.5 bg-[#f2f2f2] my-2'}></div>

                    <p className={'text-justify px-1 text-sm font-semibold'}>{selectedProduct && selectedProduct.information && selectedProduct.information[0].description}</p>

                    <div className={'text-xl opacity-50 font-bold mt-3'}>
                        {selectedProduct && selectedProduct.information && selectedProduct.information[0].per_100_gr_title}
                    </div>

                    <div className={'w-full flex justify-between items-end'}>

                        {about?.title?.map((value,index)=>(
                        <div className={'flex flex-col items-start gap-1'}>
                            <div className={'text-xl font-semibold'}>{value}</div>
                            <div className={'text-sm font-bold opacity-50'}>{about?.badge[index]}</div>
                        </div>
                        ))}

                    </div>

                    <div className={'w-full h-0.5 bg-[#f2f2f2] my-1'}></div>

                    <div className={'text-xl opacity-50 font-bold mt-3'}>Maxsulot haqida</div>

                    <p className={'text-justify px-1 text-sm font-semibold'}>{about?.description}</p>

                    <div className={'text-xl opacity-50 font-bold mt-3 '}>saqlanish muddati</div>

                    <div className={'font-semibold'}>{about?.best_b_date}</div>

                    {/*<div className={'text-xl opacity-50 font-bold mt-3 '}>Условия хранения</div>*/}

                    {/*<div className={'font-semibold'}>При температуре от +2°C до +6°C</div>*/}

                    <div className={'text-xl opacity-50 font-bold mt-3 '}>Ishlab chiqaruvchi</div>

                    <div className={'font-semibold'}>{about?.made_in}</div>
                </div>

            </div>

            {/*Right side */}
            <div className={`w-full h-auto relative pb-6`}>

                <div className={style.productInfoWrapper}>

                    <div className={'w-full h-auto flex flex-col items-start gap-3 my-3 pb-12'}>

                        <span className={'text-2xl font-semibold'}>{RECOMENDATION[0]?.title}</span>

                        <div className={'w-full flex overflow-x-auto items-center gap-3 px-3 '}>

                            {RECOMENDATION[0]?.products?.map((value,index)=> <div key={index} className="flex justify-center">
                                <ProductCard
                                    onClick={()=>{
                                        window.scrollTo(0,0)
                                        dispatch(setSelectedProducts(value))
                                    }}
                                    width={'w-32'}
                                    height={'h-36'}
                                    productQuantity={value.productQuantity}
                                    cover={value.cover}
                                    percentPromo={value.percentPromo}
                                    capacity={value.capacity}
                                    title={value.title}
                                    price={value.price}
                                    badgeText={value.badgeText}
                                />
                            </div>)}

                        </div>

                    </div>

                </div>



                <div className={` ${isActive?'bg-red-600':'bg-red-500'} w-full h-16 duration-300 px-4 mt-2 rounded-xl flex items-center justify-center gap-3 active:translate-y-0.5 duration-300 absolute bottom-3`}>
                    {isActive? <div className={'w-full h-full justify-between flex items-center gap-3 text-white select-none duration-300'}>
                        <FaMinus onClick={()=>setQuantity(quantity-1)} className={'text-xl'}/>
                        <span className={'text-xl font-bold flex items-center gap-1 line-clamp-1 relative h-full'}>
                                                {quantity>0? <div>{quantity}x</div>
                                                    :
                                                    ``
                                                }
                            <div>{selectedProduct.price} so'm</div>
                            {quantity===selectedProduct.productQuantity? <div className={'absolute  bottom-1 left-8 text-base '}>Болше нет</div> :``}
                                            </span>
                        <CgMathPlus onClick={()=>setQuantity(quantity+1)} className={'text-xl'}/>
                    </div> : <div onClick={()=>{
                        setActive(true)
                        setQuantity(1)
                    }} className={'w-full flex items-center justify-center gap-3 select-none text-white '}>
                        {selectedProduct.oldPrice? <span className={'line-through text-white/60 font-bold text-xs line-clamp-1 '}>{selectedProduct.oldPrice} so'm</span>:`` }
                        <span className={'text-xl font-bold flex items-center gap-1 line-clamp-1'}>{selectedProduct.price} so'm<CgMathPlus className={'text-xl'}/></span>
                    </div> }

                </div>

            </div>

        </div>
    </>
}
