import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import {useDispatch, useSelector} from "react-redux";
import style from "./style.module.css";
import {setDrawerOpen} from "../../components/store/actions/drawerAction";
import Mob_SelectedProductContent from "./components/Mob_SelectedProductContent/Mob_SelectedProductContent";
import Mob_SelectedPromoContent from "./components/Mob_SelectePromoContent/Mob_SelectedPromoContent";
import Mob_LoginContent from "./components/Mob_LoginContent/Mob_LoginContent";
import Mob_PaymentContent from "./components/Mob_PaymentContent/Mob_PaymentContent";
import SelectedStoryContentMobile from "./components/Mob_SelectedStoryContent/SelectedStoryContent";
import {message} from "antd";



export default function Mob_CustomDrawer({ open, onClose }) {
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();

    const itemType = useSelector((state)=>state.selectedItemType.itemType)

    useEffect(() => {

        if (open === true) {
            setOpen(true);
            document.body.style.overflow = "hidden"; // Запретить прокрутку body
        }
    }, [open]);

    const handleClose = () => {
        setTimeout(() => {
            dispatch(setDrawerOpen(false));
            document.body.style.overflow = ""; // Разрешить прокрутку body
        }, 500);
    };

    console.log(itemType)

    return (
        <>
            <div onClick={()=>{
                setOpen(false)
                handleClose()
            }}
                 className={`${
                     open ? "block" : "hidden"
                 } w-full h-full fixed top-0 left-0 flex items-center justify-end z-[1000] min-[1000px]:hidden ${isOpen ? style.container : style.containerOut} `}
            >
                <div className={'w-full h-full relative flex items-center justify-end '}>
                    <div className={'w-full h-full overflow-y-auto absolute top-0 left-0 flex items-center justify-end'}>

                        <div onClick={(e) => e.stopPropagation()} className={isOpen? style.wrapperIn : style.wrapperOut}>

                            <div className={`w-full h-full flex ${itemType === 'Payment'? `bg-[#f2f2f2]` :``} overflow-y-auto rounded-3xl items-start  relative`}>
                                {/*Close Button*/}
                                <div onClick={()=>{
                                    setOpen(false)
                                    handleClose()
                                }} className={` ${itemType === 'Payment'? `bg-[#ffffff]` :`bg-[#f2f2f2]`} cursor-pointer p-2 rounded-full  absolute right-4 top-4 text-2xl shadow z-30`}>
                                    <IoMdClose />
                                </div>

                                {/*Content Place*/}
                                {itemType === 'saleProduct'? <Mob_SelectedProductContent/> : ``}
                                {itemType === 'Promo'? <Mob_SelectedPromoContent/> : ``}
                                {itemType === 'Login'? <Mob_LoginContent/> : ``}
                                {itemType === 'Payment'? <Mob_PaymentContent/> : ``}
                                {itemType === 'Stories'? <SelectedStoryContentMobile/> : ``}


                            </div>


                        </div>

                    </div>



                </div>

            </div>
        </>
    );
}
