import React, {useEffect, useState} from "react";
import Multiple_Product_Card_Swiper from "../Multiple_Product_Card_Swiper/Multiple_Product_Card_Swiper";
import Promo_Card from "../Promo_Card/Promo_Card";
import More_Card from "../More_Card/More_Card";
import ProductCard from "../ProductCard/ProductCard";
import Offer_Card from "../Offer_Card/Offer_Card";
import {MdKeyboardArrowRight} from "react-icons/md";
import Custom_Swiper from "../Custom_Swiper/Custom_Swiper";
import example from "../assets/img/products/exampleProduct.png";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setDrawerOpen} from "../store/actions/drawerAction";
import {setSelectedProducts} from "../store/actions/selectedProductActions";
import {DIFFERENT_OFFERS, OFFERS_BY_CATEGORY, ON_SALE_PRODUCTS, PROMO_CARDS} from "../../CustomData/CustomData";
import {setItemType} from "../store/actions/selectedItemTypeAction";
import {fetchCategoriesType} from "../../Api/Categories/Categories";
import {
    extractData,
    filterProductsBySale,
    findSaleCategory,
    getRandomElementsFromArray,
    removeSliderCategory
} from "../../utils";
import {fetchProducts} from "../../Api/Products/products";
import {setPromo} from "../store/actions/promoActions";
import {addProduct} from "../store/actions/ProductActions";
import notfound from '../../components/assets/img/not_found.jpg'

export default function Main_Content({saerchwords}) {

    const dispatch = useDispatch()
    const drawerIsOpen = useSelector((state) => state.drawerState.drawer)
    const [categoriesWithType, setCategoriesWithType] = useState();
    const [products, setProducts] = useState([]);
    const [filtredProdcuts,setFiltredProducts] = useState([])
    const [categoriesType, setCategoriesType] = useState([])
    const [sale, setSale] = useState();
    const totalPrice = useSelector(state => state.price.totalPrice);
    const [randomProducts, setRandomProducts] = useState([]);

    function filterProducts(searchTerm) {
        return products?.filter(product => {
            const productNameMatches = product.name.toLowerCase().includes(searchTerm.toLowerCase());
            const brandNameMatches = product.brands.name.toLowerCase().includes(searchTerm.toLowerCase());
            const categoryNameMatches = product.categories.name.toLowerCase().includes(searchTerm.toLowerCase());

            return productNameMatches || brandNameMatches || categoryNameMatches;
        });
    }
    useEffect(()=>{},[totalPrice])
    const pom = useParams()
    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : {products: []};
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0 ? true : false,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return {isActive: false, count: 0};
        }
    };
    useEffect(() => {
        fetchTypeCategoriesApi()
        fetchProductsApi()
    }, [])

    useEffect(() => {
    }, [pom])

    const fetchTypeCategoriesApi = async () => {
        const data = fetchCategoriesType('uz')
        data.then(res => {
            setCategoriesType(res)
            setCategoriesWithType(findSaleCategory(res))
        })

    }
    const fetchProductsApi = async () => {
        const prod = await fetchProducts('uz');
        dispatch(addProduct(prod));
        setProducts(prod);


        // Устанавливаем случайные продукты только один раз при первой загрузке
        if (randomProducts.length === 0) {
            const randomItems = getRandomElementsFromArray(prod, 10);
            setRandomProducts(randomItems);
        }
    };

    useEffect(()=>{
        const data = filterProducts(saerchwords);
        setFiltredProducts(data)
    },[saerchwords])

    useEffect(()=>{
        console.log(filtredProdcuts)
    },[filtredProdcuts])


    return <>


        {/*Content Cards*/}
        <div
            className={'w-full h-auto min-h-screen bg-white max-[1000px]:px-0 px-4 block box-border z-20 rounded-b-3xl pb-12 '}>

            {saerchwords.length > 0 ?
                <div className={'flex flex-row w-full h-auto items-center max-[1000px]:justify-center  flex-wrap gap-3'}>
                    {filtredProdcuts.length > 0 ? filtredProdcuts.map(product =><ProductCard
                    onClick={() => {
                        dispatch(setDrawerOpen(true));
                        dispatch(setSelectedProducts(product));
                        dispatch(setItemType("saleProduct"));
                    }}
                    key={product.id}
                    cover={product.cover}
                    percentPromo={extractData(product.description).discount}
                    capacity={product.brief_description}
                    title={product.name}
                    oldPrice={extractData(product.description)?.minus || 0}
                    price={product.price}
                    id={product.id}
                    badgeText={product.brands}
                    initialState={getProductCount(product.id)}
                />

            ) : <img  src={notfound}/>} </div> : <>
                <div className={'w-full h-auto max-[1000px]:hidden'}>
                <Multiple_Product_Card_Swiper/>
            </div>

            {/*Promos/Акции*/}
            <div className={'w-full h-auto flex flex-col items-start max-[1000px]:mt-0 mt-6 gap-3 '}>

                <span className={'text-3xl font-semibold '}>Chegirmalar</span>

                <div className={'w-full h-72 flex flex-row items-start gap-3 max-[1440px]:hidden '}>

                    {categoriesWithType?.categories.map((value, index) => <Promo_Card obj={value} key={index}
                                                                                      title={value?.name}
                                                                                      cover={value?.icon?.icon}
                                                                                      type={value.type}/>)}

                    <More_Card link={`/products/sales-products/true/${categoriesWithType?.id}`} onClick={() => {
                        window.scrollTo(0, 0)
                    }
                    } array={categoriesWithType?.categories}
                               isSale={true}/>

                </div>

                <div className={'w-full h-72 flex flex-row items-start gap-3 max-[1280px]:hidden min-[1440px]:hidden '}>

                    {categoriesWithType?.categories.slice(0, 3).map((value, index) => <Promo_Card key={index} obj={value}   title={value?.name}
                                                                                                  cover={value?.icon?.icon}
                                                                                                  type={value.type}/>)}

                    <More_Card link={`/products/sales-products/true/${categoriesWithType?.id}`} onClick={() => window.scrollTo(0, 0)} array={categoriesWithType?.categories} isSale={true}/>

                </div>

                <div className={'w-full h-72 flex flex-row items-start gap-3 min-[1280px]:hidden max-[1000px]:hidden '}>

                    {categoriesWithType?.categories.slice(0, 2).map((value, index) => <Promo_Card key={index} obj={value}   title={value?.name}
                                                                               cover={value?.icon?.icon}
                                                                               type={value.type}/>)}

                    <More_Card link={`/products/sales-products/true/${categoriesWithType?.id}`} array={categoriesWithType?.categories} isSale={true}/>

                </div>

            </div>

            <div
                className={'w-full h-auto flex flex-col items-start max-[1000px]:mt-3 mt-6 gap-3 max-[1000px]:overflow-x-auto min-[1000px]:hidden '}>

                <div className={'h-72 flex flex-row px-4 items-start gap-3  '}>

                    {categoriesWithType?.categories.map((value, index) => <Promo_Card key={index}  obj={value}title={value?.name}
                                                                                      cover={value?.icon?.icon}
                                                                                      type={value.type}/>)}


                </div>

            </div>


            {/*Products on sale */}
            <div className={'w-full h-auto flex flex-col items-start gap-3 '}>

                <span className={'text-3xl font-semibold '}>Foydali savatcha</span>

                <div className={'w-full h-72 flex flex-row items-start gap-3 max-[1190px]:hidden '}>

                    {filterProductsBySale(products).slice(0, 4).map((product, index) => <ProductCard onClick={() => {
                        dispatch(setDrawerOpen(true))
                        dispatch(setSelectedProducts(product))
                        dispatch(setItemType("saleProduct"))
                    }}
                                                                                                     key={index}
                                                                                                     cover={product.cover}
                                                                                                     percentPromo={0}
                                                                                                     capacity={product.brief_description}
                                                                                                     title={product.name}
                                                                                                     oldPrice={'1000'}
                                                                                                     price={product.price}
                                                                                                     id={product.id}
                                                                                                     badgeText={product.brands}
                                                                                                     initialState={getProductCount(product.id)}/>)}

                    <More_Card productSale={true} height={'h-44'} link={'/products/sale/true'}
                               array={filterProductsBySale(products).slice(0, 4)}/>

                </div>

                <div className={'w-full h-72 flex flex-row items-start gap-3 max-[1000px]:hidden min-[1190px]:hidden '}>

                    {filterProductsBySale(products).slice(0, 3).map((product, index) => <ProductCard onClick={() => {
                        dispatch(setDrawerOpen(true))
                        dispatch(setSelectedProducts(product))
                        dispatch(setItemType("saleProduct"))
                    }}
                                                                                     key={index}
                                                                                                   cover={product.cover}
                                                                                                   percentPromo={0}
                                                                                                   capacity={product.brief_description}
                                                                                                   title={product.name}
                                                                                                   oldPrice={'1000'}
                                                                                                   price={product.price}
                                                                                                   id={product.id}
                                                                                                   badgeText={product.brands}
                                                                                                   initialState={getProductCount(product.id)}/>)}


                </div>


                <div className={'w-full h-72 flex flex-row items-start gap-3  min-[1000px]:hidden '}>

                    <Custom_Swiper array={filterProductsBySale(products)}/>

                </div>

            </div>
            {/*Products on sale Category/Just in case */}
            <div className={'w-full h-auto flex flex-col items-start gap-3 mt-6 '}>
                <div className={'w-full flex justify-between items-center'}>

                    <span className={'text-3xl font-semibold '}>Har xil xolatlar uchun</span>

                    {/*<div className={'flex items-center gap-1 cursor-pointer'}>*/}

                    {/*    <span className={'text-base font-semibold '}>Ko`proq</span>*/}

                    {/*    <div className={'p-1 rounded-full bg-[#f2f2f2] '}>*/}

                    {/*        <MdKeyboardArrowRight/>*/}

                    {/*    </div>*/}

                    {/*</div>*/}

                </div>
                <div className={'w-full h-72 flex flex-row items-start gap-3 '}>

                    <Custom_Swiper array={randomProducts} />


                </div>

            </div>
            {
              removeSliderCategory(categoriesType).map(value => <>
                        <span className={'text-3xl font-semibold '}>{value.name}</span>
                        <div
                            className={'w-full h-auto flex flex-wrap max-[1000px]:justify-center justify-start items-start gap-3 mt-6 '}>

                            {value.categories.map((category, index) => <Offer_Card link={`/products/${category?.id}/${category?.name}/${value?.name}/${value?.id}`} key={index} title={category.name}
                                                                                cover={category.icon.icon}/>)}

                        </div>
                    </>
                )
            }




            <div onClick={() => {
                dispatch(setDrawerOpen(true))
                dispatch(setItemType('Payment'))
            }}
                 className={'fixed bottom-6 right-3 px-6 py-2 rounded-full bg-red-600 text-white flex flex-col items-center min-[1000px]:hidden  z-30'}>

                <div className={'text-balance font-bold'}>{totalPrice} so'm</div>
                <div className={'text-xs font-bold text-white/60'}>savatcha</div>

            </div>
            </>
            }


        </div>
    </>
}
