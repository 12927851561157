import {Link, Route, Routes, useParams} from "react-router-dom";
import {HiMiniUser} from "react-icons/hi2";
import {CiSearch} from "react-icons/ci";


import Main_Content from "./components/Main_Content/Main_Content";
import Category_Products_Layout from "./components/Category_Products_Layout/Category_Products_Layout";
import {Drawer, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setDrawerOpen} from "./components/store/actions/drawerAction";
import CustomDrawer from "./components/CustomDrawer/CustomDrawer";
import React, {useCallback, useEffect, useState} from "react";
import DropdownMenu from "./components/DropdownMenu/DropdownMenu";
import Mob_Multiple_Product_Card_Swiper from "./MobileComponents/Mob_Multiple_Product_Card_Swiper/Mob_Multiple_Product_Card_Swiper";
import {CATEGORIES, FOOTER_LINKS} from "./CustomData/CustomData";
import {IoMoonSharp} from "react-icons/io5";
import appStore from "./components/assets/img/logo/store_app_store.svg";
import playStore from "./components/assets/img/logo/store_google_play.svg";
import ruStore from "./components/assets/img/logo/store_ru_store.svg";
import huaweiStore from "./components/assets/img/logo/store_app_gallery.svg";
import {FaChevronDown, FaOdnoklassniki, FaTiktok, FaYoutube} from "react-icons/fa";
import {SlSocialVkontakte} from "react-icons/sl";
import {setItemType} from "./components/store/actions/selectedItemTypeAction";
import MapContainer from "./components/MapContainer/MapContainer";
import Mob_CustomDrawer from "./MobileComponents/Mob_CustomDrawer/Mob_CustomDrawer";
import {selectIsUserLoggedIn, selectUserData} from "./components/store/selectors/userSelectors";
import fetchCategories, {fetchCategoriesType} from "./Api/Categories/Categories";
import logo from './components/assets/img/logo/logo.png';
import {removeSliderCategory} from "./utils";
import {selectDrawerOpen} from "./components/store/selectors/drawerSelector";
import {useTelegram} from "./hooks/TelegramHooh";
function App() {

    const dispatch = useDispatch()
    const drawerIsOpen = useSelector((state)=>state.drawerState.drawer)
    const isLogin = useSelector(selectIsUserLoggedIn);
    const userData = useSelector(selectUserData);
    const isDrawerOpen = useSelector(selectDrawerOpen);
    const [isTgUser,setTgUser] = useState(false);
    const [searchWords,setSearchWords] = useState('')
    const isTelegramWebApp = window.location.href.startsWith('https://t.me/app/');
    const products = useSelector(state => state.products)
    const pom = useParams();
    const {tg,user,onClose,queryId} = useTelegram()
    const APP_VERSION = '1.0.2V'

    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : { products: [] };
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return { isActive: false, count: 0 };
        }
    };



    if (localStorage.getItem('basket') === null) {
        localStorage.setItem('basket', '{"products":[{"product_id":0,"count":0}]}');
    }
    // {id, first_name, last_name, username, language_code, allows_write_to_pm})
    const [categories,setCategories] = useState([]);
        const fetchCategoriesApi = async ()=>{
            const data = await fetchCategoriesType('uz');
            setCategories(removeSliderCategory(data))
        }
    const data = {
        user: user?.name
    }
    const onSendData = useCallback(() => {

        tg.sendData(JSON.stringify(data));
    }, [])

    useEffect(() => {

    }, [pom]);
    useEffect(()=>{

        if (user?.id?.toString().length > 0){
            tg.disableVerticalSwipes();
            setTgUser(true)
        } else {
            setTgUser(false)
        }
    },[])


    useEffect(() => {
        console.log(products)
    }, [products])
        useEffect(()=>{

            fetchCategoriesApi()
          console.log(APP_VERSION)
            if (localStorage.getItem('app_version') !== APP_VERSION){
                localStorage.clear()
                 window.location.reload()
                localStorage.setItem('app_version',APP_VERSION)
            }
        },[])

    const closeFunc = ()=>{
        dispatch(setDrawerOpen(false))
    }
    useEffect(()=>{



    },[isLogin,userData,isDrawerOpen])


    const search = (e)=>{
      // const data =   filterProducts(e.target.value)
       setSearchWords(e.target.value)
    }

    return (
        <div className={` w-full flex flex-row justify-between h-auto min-h-screen max-[1000px]:px-1 px-4 bg-[#f2f2f2]`}>

            {/*Drawers place */}

            {/*This one for desktop*/}
            <CustomDrawer open={drawerIsOpen}/>

            <Mob_CustomDrawer open={drawerIsOpen}/>

            {/*Left Sidebar*/}
            <div className={'w-64 max-[1280px]:w-60 h-screen max-[1000px]:hidden sticky top-0'}>

                <div className={'w-64 max-[1280px]:w-60 h-full flex flex-col items-end gap-6'}>


                    {/*Logo*/}
                    <div className={'w-full h-24 rounded-b-3xl flex items-center pl-1 bg-[#ffffff]'}>

                        <div className={'h-20 w-auto '}>

                            <img src={logo} className={'w-fit h-full object-contain'} alt={'logo'}/>

                        </div>

                        <span className={'text-3xl font-semibold font-mono text-blue-900'}><span className={'text-3xl font-semibold text-green-500 font-mono'}>Oson</span>Express</span>

                    </div>

                    {/*Categories*/}
                    <div
                        className={'w-full h-full flex flex-col items-start p-3 overflow-hidden rounded-t-3xl bg-[#ffffff]  '}>

                        <div className={'w-full h-auto flex flex-col items-start text-start gap-3 overflow-y-auto '}>

                            {/*Links*/}
                            {/*SideBar Menu*/}
                            {categories?.map((value, index) => <DropdownMenu value={value} array={value?.categories}/>)}


                        </div>

                    </div>


                </div>


            </div>

            {/*Main Content*/}
            <div className={'w-full h-full min-h-screen flex flex-col items-start max-[1000px]:px-0 px-3 pb-6 overflow-x-clip '}>

                <div className={'w-full h-auto sticky top-0 z-0 min-[1000px]:hidden py-6  '}>
                    <div className={'w-full flex items-center justify-between px-3'}>

                        <div className={'flex items-center '}>

                            <div className={'w-full text-start text-2xl line-clamp-1 font-bold mb-3'}>{isTgUser? user?.first_name :  isLogin?userData?.address:' адресс не установлен'}</div>

                            <FaChevronDown onClick={()=>{

                                dispatch(setItemType('Location'))
                                dispatch(setDrawerOpen(true))
                            }} className={'select-none ml-2 text-xl cursor-pointer rounded-full duration-200  active:bg-[#f2f2f2] active:translate-y-0.5 '} />

                        </div>

                        <div onClick={()=>{

                                    dispatch(setItemType('Login'))
                                    dispatch(setDrawerOpen(true))

                        }} className={'text-2xl'}>
                            <HiMiniUser/>
                        </div>

                    </div>

                    <Mob_Multiple_Product_Card_Swiper/>

                </div>

                <div className={'w-full z-20 bg-[#f2f2f2] '}>


                    {/*Top sticky bar*/}
                    <div className={'w-full sticky top-0  overflow-hidden bg-[#f2f2f2] z-30 '}>

                        {/*Working timeline 24/7 */}
                        <div className={'w-full h-20 bg-[#ffffff] rounded-b-3xl max-[1000px]:hidden flex items-center gap-6 px-8 '}>

                            <IoMoonSharp className={'text-2xl'} />

                            <div className={'text-xl font-bold'}>Ish vaqti  08:00 dan  24:00 gacha</div>

                        </div>

                        {/*Search bar*/}
                        <div
                            className={'w-full h-24 p-5 bg-[#ffffff] max-[1000px]:mt-0 mt-6 rounded-t-3xl flex flex-col items-center justify-center relative '}>

                            <div className={'text-4xl text-black/80 absolute top-[30px] left-8'}>
                                <CiSearch/>
                            </div>
                            <input onChange={search} placeholder={'Искать у Нас'} type={"text"} className={'w-full h-full bg-[#f2f2f2] rounded-full pl-14 active:bg-[#f9f9f9] outline-0'}/>

                        </div>

                    </div>


                    <Routes>

                        <Route path={'/'} element={<Main_Content saerchwords={searchWords}/>}/>
                        <Route path={'/:telegram'} element={<Main_Content/>}/>
                        <Route path={'/layout/:id/:title'} element={<Category_Products_Layout/>}/>
                        <Route path={'/products/:id/:title/:big/:type'} element={<Category_Products_Layout/>}/>
                        <Route path={'/products/:id/:title/:big/:type/:isSale'} element={<Category_Products_Layout/>}/>
                        <Route path={'/products/sale/:isSale'} element={<Category_Products_Layout/>}/>
                        <Route path={'/products/sales-products/:doSale/:id'} element={<Category_Products_Layout/>}/>
                    </Routes>


                </div>

                {/*Main footer*/}
                <div className={'w-full h-auto bg-[#f2f2f2] sticky bottom-0 z-0 flex flex-col items-start max-[1000px]:p-1 max-[1000px]:gap-1 gap-3 p-5'}>


                    <div className={'w-full flex items-center justify-between '}>

                        {/*<div className={'flex items-center max-[1000px]:gap-1 gap-3 flex-wrap justify-start'}>*/}

                        {/*    <div className={'w-28 h-11 rounded-lg bg-[#e0e0e0] overflow-hidden relative'}>*/}
                        {/*        <img className={'w-full h-full absolute top-0 left-0 object-contain'} src={appStore} alt={''}/>*/}
                        {/*    </div>*/}
                        {/*    <div className={'w-28 h-11 rounded-lg bg-[#e0e0e0] overflow-hidden relative'}>*/}
                        {/*        <img className={'w-full h-full absolute top-0 left-0 object-contain'} src={playStore} alt={''}/>*/}
                        {/*    </div>*/}
                        {/*    <div className={'w-28 h-11 rounded-lg bg-[#e0e0e0] overflow-hidden relative'}>*/}
                        {/*        <img className={'w-full h-full absolute top-0 left-0 object-contain'} src={huaweiStore} alt={''}/>*/}
                        {/*    </div>*/}
                        {/*    <div className={'w-28 h-11 rounded-lg bg-[#e0e0e0] overflow-hidden relative'}>*/}
                        {/*        <img className={'w-full h-full absolute top-0 left-0 object-contain'} src={ruStore} alt={''}/>*/}
                        {/*    </div>*/}

                        {/*</div>*/}

                        {/*<div className={'flex items-center max-[1000px]:gap-1 gap-3 flex-wrap justify-end text-xl '}>*/}

                        {/*    <div className={' p-3 rounded-lg bg-[#e0e0e0] '}>*/}
                        {/*        <FaTiktok />*/}
                        {/*    </div>*/}
                        {/*    <div className={'p-3 rounded-lg bg-[#e0e0e0] '}>*/}
                        {/*        <SlSocialVkontakte />*/}
                        {/*    </div>*/}
                        {/*    <div className={'p-3 rounded-lg bg-[#e0e0e0] '}>*/}
                        {/*        <FaOdnoklassniki />*/}
                        {/*    </div>*/}
                        {/*    <div className={'p-3 rounded-lg bg-[#e0e0e0] '}>*/}
                        {/*        <FaYoutube />*/}
                        {/*    </div>*/}

                        {/*</div>*/}

                    </div>

                    <div className={'w-auto max-[460px]:h-auto max-[680px]:h-44 max-[1000px]:h-32 min-[1320px]:h-32  max-[1320px]:h-44 overflow-hidden max-[1000px]:gap-0 gap-3 flex flex-col flex-wrap '}>

                        {FOOTER_LINKS.map((value, index)=> <div key={index} className={'max-[1000px]:text-sm text-balance font-bold text-black/70'}>{value}</div>)}

                    </div>

                    <div className={'w-full max-[1000px]:text-[12px] text-sm font-bold text-black/40 '}>
                        OsonExpress Barcha tovarlar sifatga javob beradi.
                    </div>

                </div>

            </div>


            {/*Right SideBar*/}
            <div className={'w-80 max-[1280px]:w-60 h-screen max-[1000px]:hidden sticky top-0 '}>

                <div className={'w-80 max-[1280px]:w-60 h-full flex flex-col items-end gap-6 py-8'}>


                    {/*Login/Sign Up*/}
                    <div onClick={()=>{
                        dispatch(setItemType('Login'))
                        dispatch(setDrawerOpen(true))
                    }} className={'cursor-pointer w-full h-12 rounded-full flex items-center justify-center gap-3 bg-[#ffffff]'}>

                        <div className={'text-2xl'}>
                            <HiMiniUser/>
                        </div>
                        <span className={'text-xl font-semibold font-mono'}>
                            { localStorage.getItem('login') ? localStorage.getItem('user') : 'Войти'}


                        </span>

                    </div>

                    {/*Map Location*/}
                    <div className={'w-full h-full rounded-3xl bg-[#ffffff] relative flex flex-col overflow-hidden '}>

                        <MapContainer/>

                    </div>


                </div>


            </div>

        </div>
    );
}

export default App;
